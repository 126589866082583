import domReady from '@wordpress/dom-ready';

domReady(() => {
  const allData = document.querySelectorAll('.tabs-wrapper__tab[data-tab]');
  const imgContainer = document.querySelector(
    '.indexation-tabs__image-container'
  );

  allData[0].classList.add('tab-opened');
  imgContainer.querySelectorAll('img')[0].classList.add('tab-opened');

  allData.forEach((dataTab) => {
    dataTab.addEventListener('click', function () {
      allData.forEach((element) => {
        if (element.classList.contains('tab-opened')) {
          element.classList.remove('tab-opened');
        }
      });
      imgContainer.querySelector('.tab-opened').classList.remove('tab-opened');

      const tabImage = imgContainer.querySelector(
        '[data-tab="' + dataTab.dataset.tab + '"]'
      );
      dataTab.classList.add('tab-opened');
      tabImage.classList.add('tab-opened');
    });
  });
});
